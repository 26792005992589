package eu.drewnomaniak.styles

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import eu.drewnomaniak.models.Theme
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px

val ServiceCardStyle by ComponentStyle {
    base {
        Modifier
            .border(
                width = 2.px,
                style = LineStyle.Solid,
                color = Theme.Secondary.rgb
            )
            .borderRadius(
                topLeft = 30.px,
                topRight = 30.px,
                bottomLeft = 30.px,
                bottomRight = 0.px
            )
            .backgroundColor(Theme.Black.rgb)
            .transition(CSSTransition(property = "border", duration = 200.ms))
            .transition(CSSTransition(property = "background", duration = 200.ms))
    }
    hover {
        Modifier
            .border(
                width = 2.px,
                style = LineStyle.Solid,
                color = Theme.Secondary.rgb
            )
            .borderRadius(
                topLeft = 30.px,
                topRight = 30.px,
                bottomLeft = 30.px,
                bottomRight = 0.px
            )
            .backgroundColor(Theme.Secondary.rgb)
    }

    cssRule(" > #iconBox") {
        Modifier
            .backgroundColor(Colors.Transparent)
            .border(width = 2.px, style = LineStyle.Solid, color = Theme.Secondary.rgb)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
                CSSTransition(property = "border", duration = 200.ms),
            )
    }

    cssRule(":hover > #iconBox") {
        Modifier
            .backgroundColor(Theme.Black.rgb)
            .border(width = 2.px, style = LineStyle.Solid, color = Theme.Secondary.rgb)
    }

    cssRule(" > P") {
        Modifier.color(Theme.Brown.rgb)
            .transition(CSSTransition(property = "color", duration = 200.ms))
    }

    cssRule(":hover > P") {
        Modifier.color(Theme.LightBrown.rgb)
    }
}