package eu.drewnomaniak.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.core.Page
import eu.drewnomaniak.components.OverflowMenu
import eu.drewnomaniak.models.Theme
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import eu.drewnomaniak.components.OverflowPhotoBox
import eu.drewnomaniak.sections.*

@Page
@Composable
fun HomePage() {
    var menuOpened by remember { mutableStateOf(false) }
    var photoOpened by remember { mutableStateOf<String?>(null) }

    var opacity by remember { mutableStateOf(0.percent) }

    LaunchedEffect(Unit) {
        delay(500)
        opacity = 100.percent
    }

    Box(modifier = Modifier.fillMaxSize().backgroundColor(Theme.Black.rgb)) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .opacity(opacity)
                .transition(CSSTransition(property = "opacity", duration = 1500.ms)),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            MainSection(onMenuClicked = { menuOpened = true })
            ServiceSection()
            PortfolioSection(onPhotoClicked = { photo -> photoOpened = photo })
            MessageContactSection()
            PhoneContactSection()
        }

        if (menuOpened) {
            OverflowMenu(onMenuClosed = { menuOpened = false })
        }
    }

    photoOpened?.let {
        OverflowPhotoBox(
            photo = it,
            onBoxClosed = {
                photoOpened = null
            },
            onSelectNext = {
                val currentIndex = portfolioPhotos.indexOf(photoOpened)
                portfolioPhotos.getOrNull(currentIndex + 1)?.let { nextPhoto ->
                    photoOpened = nextPhoto
                }
            },
            onSelectPrevious = {
                val currentIndex = portfolioPhotos.indexOf(photoOpened)
                portfolioPhotos.getOrNull(currentIndex - 1)?.let { previousPhoto ->
                    photoOpened = previousPhoto
                }
            }
        )
    }
}
