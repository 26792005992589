package eu.drewnomaniak.models

enum class Section(
    val id: String,
    val title: String,
    val subtitle: String,
    val path: String
) {
    Home(
        id = "home",
        title = "Drewnomaniak",
        subtitle = "",
        path = "#home"
    ),
    Service(
        id = "service",
        title = "Oferta",
        subtitle = "Moje usługi",
        path = "#service"
    ),
    Portfolio(
        id = "portfolio",
        title = "Portfolio",
        subtitle = "Moje prace",
        path = "#portfolio"
    ),
    MessageContact(
        id = "contact",
        title = "Kontakt",
        subtitle = "Napisz wiadomość",
        path = "#contact"
    ),
    PhoneContact(
        id = "phonecontact",
        title = "Preferujesz rozmowę?",
        subtitle = "Zadzwoń - pogadajmy",
        path = "#phonecontact"
    ),
}
