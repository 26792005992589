package eu.drewnomaniak.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import eu.drewnomaniak.components.SectionTitle
import eu.drewnomaniak.models.Section
import eu.drewnomaniak.models.Theme
import eu.drewnomaniak.utils.Constants
import eu.drewnomaniak.utils.ObserveViewportEntered
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun PhoneContactSection() {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(Theme.DarkBase.rgb)
            .id(Section.PhoneContact.id)
            .padding(topBottom = 100.px),
//            .maxWidth(SECTION_WIDTH.px),
        contentAlignment = Alignment.Center
    ) {
        ContactContent()
    }
}

@Composable
private fun ContactContent() {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    var animatedRotation by remember { mutableStateOf(0.deg) }

    ObserveViewportEntered(
        sectionId = Section.PhoneContact.id,
        distanceFromTop = 500.0,
        onViewportEntered = {
            animatedRotation = 10.deg
            scope.launch {
                delay(500)
                animatedRotation = 0.deg
            }
        }
    )

    Column(
        modifier = Modifier
            .fillMaxWidth(if (breakpoint >= Breakpoint.MD) 100.percent else 90.percent),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SectionTitle(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 25.px)
                .transform { rotate(animatedRotation) }
                .transition(CSSTransition(property = "transform", duration = 500.ms)),
            section = Section.PhoneContact,
            alignment = Alignment.CenterHorizontally
        )
        P(
            attrs = Modifier
                .margin(top = 10.px, bottom = 5.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(20.px)
                .fontWeight(FontWeight.Bold)
                .color(Theme.LightBrown.rgb)
                .toAttrs()
        ) {
            Text("+48 537 520 482")
        }
        P(
            attrs = Modifier
                .margin(bottom = 25.px)
                .maxWidth(400.px)
                .fontFamily(Constants.FONT_FAMILY)
                .fontSize(15.px)
                .fontStyle(FontStyle.Italic)
                .fontWeight(FontWeight.Normal)
                .color(Theme.Brown.rgb)
                .toAttrs()
        ) {
            Text("Znajdziesz mnie również na WhatsApp")
        }
    }
}