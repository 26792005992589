package eu.drewnomaniak.models

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb

enum class Theme(
    val hex: String,
    val rgb: CSSColorValue
) {
    Primary(hex = "#564D4A", rgb = rgb(r = 86, g = 77, b = 74)),
    Secondary(hex = "#1B262C", rgb = rgb(r = 27, g = 38, b = 44)),
    Blue(hex = "#3282B8", rgb = rgb(r = 50, g = 130, b = 184)),
    LightBlue(hex = "#BBE1FA", rgb = rgb(r = 187, g = 225, b = 250)),
    DarkBase(hex = "#181b1c", rgb = rgb(r = 24, g = 27, b = 28)),
    Black(hex = "#121212", rgb = rgb(r = 18, g = 18, b = 18)),
    Brown(hex = "#AA968A", rgb = rgb(r = 170, g = 150, b = 138)),
    LightBrown(hex = "#D2C7C1", rgb = rgb(r = 210, g = 199, b = 193)),
    Gray(hex = "#6E6A6F", rgb = rgb(r = 110, g = 106, b = 111)),
    LightGray(hex = "#D4D2D5", rgb = rgb(r = 212, g = 210, b = 213)),
}