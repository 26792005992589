package eu.drewnomaniak.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.argb
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.shapes.RectF
import com.varabyte.kobweb.silk.theme.shapes.clip
import eu.drewnomaniak.components.Header
import eu.drewnomaniak.components.SocialBar
import eu.drewnomaniak.models.Section
import eu.drewnomaniak.models.Theme
import eu.drewnomaniak.styles.MainButtonStyle
import eu.drewnomaniak.styles.MainImageBoxStyle
import eu.drewnomaniak.styles.MainImageStyle
import eu.drewnomaniak.utils.Constants.FONT_FAMILY
import eu.drewnomaniak.utils.Res
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun MainSection(onMenuClicked: () -> Unit) {
    Box(
        modifier = Modifier
            .fillMaxSize()
            .id(Section.Home.id),
        contentAlignment = Alignment.Center
    ) {
        MainBackground()
        MainContent(onMenuClicked = onMenuClicked)
    }
}

@Composable
private fun MainBackground() {
    val backgroundImageUrl = url(Res.Image.background)

//    Box(
//        modifier = Modifier
//            .fillMaxWidth()
//            .fillMaxHeight()
//            .objectFit(ObjectFit.ScaleDown)
//            .background(Background.of(BackgroundImage.of(backgroundImageUrl)))
////            .backgroundRepeat(BackgroundRepeat.Repeat)
//    )

    Image(
        modifier = Modifier
            .fillMaxSize()
            .objectFit(ObjectFit.Cover),
        src = Res.Image.background,
    )

    Box(modifier = Modifier.fillMaxSize()
        .backgroundColor(argb(a = 0.75f, r = 0.0f, g = 0.0f, b = 0.0f)))

    Image(
        modifier = Modifier
            .fillMaxSize()
            .objectFit(ObjectFit.Cover),
        src = Res.Image.background_cover,
    )
}

val RotateKeyframes = Keyframes {
    from { Modifier.rotateX(0.deg) }
    to { Modifier.rotateX(360.deg) }
}

@Composable
fun MainContent(onMenuClicked: () -> Unit) {
    val breakpoint = rememberBreakpoint()

    var mainTextMargin by remember { mutableStateOf((-100).percent) }
    var mainImageMargin by remember { mutableStateOf(100.percent) }

    LaunchedEffect(Unit) {
        delay(600)
        mainTextMargin = 0.percent
        mainImageMargin = 0.percent
    }

    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Header(onMenuClicked = onMenuClicked)
        Column(
            modifier = Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SimpleGrid(
                modifier = Modifier.fillMaxWidth(
                    if (breakpoint >= Breakpoint.MD) 80.percent else 90.percent
                ),
                numColumns = numColumns(base = 1, md = 2)
            ) {
                MainText(breakpoint = breakpoint, modifier = Modifier.translateX(mainTextMargin).transition(CSSTransition(property = "translate", duration = 800.ms)))
                MainImage(breakpoint = breakpoint, modifier = Modifier.translateX(mainImageMargin).transition(CSSTransition(property = "translate", duration = 800.ms)))
            }
        }
    }
}

@Composable
fun MainText(
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier
) {
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
    ) {
        if (breakpoint > Breakpoint.MD) {
            SocialBar()
        }
        Column {
            P(
                attrs = Modifier
                    .margin(topBottom = 0.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(if (breakpoint >= Breakpoint.LG) 45.px else 20.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Gray.rgb)
                    .toAttrs()
            ) {
                Text("Cześć! Z tej strony")
            }
            P(
                attrs = Modifier
                    .margin(top = 20.px, bottom = 0.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(if (breakpoint >= Breakpoint.LG) 68.px else 40.px)
                    .fontWeight(FontWeight.Bolder)
                    .color(Theme.LightBrown.rgb)
                    .toAttrs()
            ) {
                Text("Drewno Maniak")
            }
            P(
                attrs = Modifier
                    .margin(top = 10.px, bottom = 5.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(20.px)
                    .fontWeight(FontWeight.Bold)
                    .color(Theme.LightBrown.rgb)
                    .toAttrs()
            ) {
                Text("Konstruuję drewniane konstrukcje z pasją")
            }
            P(
                attrs = Modifier
                    .margin(bottom = 25.px)
                    .maxWidth(400.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(15.px)
                    .fontStyle(FontStyle.Italic)
                    .fontWeight(FontWeight.Normal)
                    .color(Theme.Brown.rgb)
                    .toAttrs()
            ) {
                Text(Res.Text.drewnomaniak_short_description)
            }
            Button(
                attrs = MainButtonStyle.toModifier()
                    .height(40.px)
                    .border(width = 0.px)
                    .borderRadius(r = 5.px)
                    .backgroundColor(Theme.Primary.rgb)
                    .color(Colors.White)
                    .cursor(Cursor.Pointer)
                    .margin(bottom = 25.px)
                    .toAttrs()
            ) {
                Link(
                    modifier = Modifier
                        .color(Colors.White)
                        .textDecorationLine(TextDecorationLine.None),
                    text = "Kontakt",
                    path = Section.MessageContact.path
                )
            }
        }
    }
}

@Composable
fun MainImage(
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier
            .fillMaxSize(80.percent)
            .fillMaxHeight(),
        verticalArrangement = Arrangement.Center
    ) {
        Image(
            modifier = Modifier
                .fillMaxWidth(),
            src = Res.Image.logo,
        )
    }
}
