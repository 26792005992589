package eu.drewnomaniak.models

import eu.drewnomaniak.utils.Res

enum class Service(
    val icon: String,
    val imageDesc: String,
    val title: String,
    val description: String
) {
    Terraces(
        icon = Res.Icon.terrace,
        imageDesc = "Android Icon",
        title = "Budowa tarasów",
        description = "Projektowanie i wykonywanie tarasów z drewna, idealnych do relaksu na świeżym powietrzu, z opcjami dodatkowych elementów, takich jak balustrady i oświetlenie."
    ),
    Sheds(
        icon = Res.Icon.roof,
        imageDesc = "Roof Icon",
        title = "Konstrukcja wiat",
        description = "Tworzenie funkcjonalnych wiat dla samochodów, sprzętu ogrodowego czy grillów, dostosowanych do indywidualnych potrzeb klienta."
    ),
    UtilityBuildings(
        icon = Res.Icon.utility_buildings,
        imageDesc = "Utility buildings Icon",
        title = "Mniejsze budynki gospodarcze",
        description = "Budowa niewielkich obiektów gospodarczych, takich jak schowki na narzędzia czy garaże, z dbałością o detale i estetykę."
    ),
    Technologies(
        icon = Res.Icon.advisory,
        imageDesc = "Advisory Icon",
        title = "Doradztwo i planowanie",
        description = "Pomoc w planowaniu projektów, wyborze odpowiednich materiałów i najlepszego rozwiązania dostosowanego do potrzeb klienta."
    ),
    Transparency(
        icon = Res.Icon.bench,
        imageDesc = "Bench Icon",
        title = "Budowa elementów małej architektury",
        description = "Realizacja projektów takich jak pergole, huśtawki ogrodowe i donice z drewna, które wzbogacą przestrzeń ogrodową i dodadzą jej charakteru."
    ),
    Price(
        icon = Res.Icon.money,
        imageDesc = "Money Icon",
        title = "Konkurencyjna cena",
        description = "Oferuję rozsądną, konkurencyjną cenę za świadczone usługi. Na pewno się dogadamy!"
    )
}