package eu.drewnomaniak.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.shapes.RectF
import com.varabyte.kobweb.silk.theme.shapes.clip
import eu.drewnomaniak.models.Theme
import eu.drewnomaniak.styles.MainImageStyle
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun Gallery(
    images: List<String>,
    onPhotoClicked: (String) -> Unit,
) {
    Column(
        modifier = Modifier
            .fillMaxWidth(85.percent)
            .padding(bottom = 20.px),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Row(
            modifier = Modifier
                .id("scrollableContainer")
                .fillMaxWidth()
                .margin(25.px)
                .overflow(Overflow.Hidden)
                .scrollBehavior(ScrollBehavior.Smooth)
        ) {
            for (image in images) {
                Image(
                    modifier = MainImageStyle.toModifier()
                        .height(200.px)
                        .clip(RectF(cornerRadius = 5.percent))
                        .cursor(Cursor.Pointer)
                        .margin(right = if (image != images.last()) 15.px else 0.px)
                        .onClick { onPhotoClicked(image) }
                        .border(width = 2.px, style = LineStyle.Solid, color = Theme.DarkBase.rgb)
                        .borderRadius(5.percent)
                        .objectFit(ObjectFit.Fill)
                        .clip(RectF(cornerRadius = 5.percent)),
                    src = image,
                )
            }
        }
    }
}
