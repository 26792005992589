package eu.drewnomaniak.components

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.argb
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.shapes.RectF
import com.varabyte.kobweb.silk.theme.shapes.clip
import eu.drewnomaniak.styles.PortfolioCircleArrowIconStyle
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*

@Composable
fun OverflowPhotoBox(
    photo: String?,
    onBoxClosed: () -> Unit,
    onSelectNext: () -> Unit,
    onSelectPrevious: () -> Unit,
) {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    var opacity by remember { mutableStateOf(0.percent) }

    fun closeBox() {
        scope.launch {
            opacity = 0.percent
            delay(500)
            onBoxClosed()
        }
    }

    LaunchedEffect(photo) {
        opacity = if (photo != null) 100.percent else 0.percent
    }

    photo?.let {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(argb(a = 0.75f, r = 0.0f, g = 0.0f, b = 0.0f))
                .overflow(Overflow.Auto)
                .scrollBehavior(ScrollBehavior.Smooth)
                .position(Position.Fixed)
                .zIndex(2)
                .opacity(opacity)
                .transition(Transition.of(property = "opacity", duration = 500.ms)),
        ) {
            Image(
                src = photo,
                modifier = Modifier
                    .fillMaxWidth()
                    .fillMaxHeight()
                    .padding(if (breakpoint >= Breakpoint.MD) 80.px else 30.px)
                    .align(Alignment.Center)
                    .objectFit(ObjectFit.Contain)
                    .zIndex(3)
                    .clip(RectF(cornerRadius = 2.percent))
                    .borderRadius(r = 100.px),
            )
            FaXmark(
                modifier = Modifier
                    .color(Colors.White)
                    .cursor(Cursor.Pointer)
                    .margin(right = 20.px, top = 10.px)
                    .align(Alignment.TopEnd)
                    .padding(30.px)
                    .zIndex(4)
                    .onClick { closeBox() },
                size = IconSize.XL,
            )
            PortfolioPhotosOverflowBoxNavigation(
                modifier = Modifier.zIndex(5),
                onSelectNext = onSelectNext,
                onSelectPrevious = onSelectPrevious,
                breakpoint = breakpoint,
            )
        }
    }
}

@Composable
private fun BoxScope.PortfolioPhotosOverflowBoxNavigation(
    modifier: Modifier = Modifier,
    breakpoint: Breakpoint,
    onSelectNext: () -> Unit,
    onSelectPrevious: () -> Unit,
) {
    Row(
        modifier = modifier
            .fillMaxWidth()
            .align(Alignment.Center),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        FaCircleArrowLeft(
            modifier = PortfolioCircleArrowIconStyle.toModifier()
                .padding(if (breakpoint >= Breakpoint.MD) 40.px else 25.px)
                .cursor(Cursor.Pointer)
                .onClick { onSelectPrevious() },
            size = IconSize.XXL
        )

        FaCircleArrowRight(
            modifier = PortfolioCircleArrowIconStyle.toModifier()
                .padding(if (breakpoint >= Breakpoint.MD) 40.px else 25.px)
                .cursor(Cursor.Pointer)
                .onClick { onSelectNext() },
            size = IconSize.XXL
        )
    }
}