package eu.drewnomaniak.utils

object Constants {
    const val WEBSITE = "http://drewnomaniak.eu"
    const val FONT_FAMILY = "Roboto"
}

object Res {
    object Icon {
        const val roof = "roof.svg"
        const val terrace = "terrace.svg"
        const val bench = "bench.svg"
        const val advisory = "advisory.svg"
        const val money = "cash.svg"
        const val utility_buildings = "utility_buildings.svg"
    }

    object Image {
        const val background = "drew_background_gr.jpg"
        const val background_cover = "background_cover.svg"
        const val logo = "logo.svg"
        const val logo_plain = "logo_plain.svg"

        const val drewn_1 = "drewn_1.jpg"
        const val drewn_2 = "drewn_2.jpg"
        const val drewn_3 = "drewn_3.jpg"
        const val drewn_4 = "drewn_4.jpg"
        const val drewn_5 = "drewn_5.jpg"
        const val drewn_6 = "drewn_6.jpg"
        const val drewn_7 = "drewn_7.jpg"
        const val drewn_8 = "drewn_8.jpg"
        const val drewn_9 = "drewn_9.jpg"
        const val drewn_10 = "drewn_10.jpg"
        const val drewn_11 = "drewn_11.jpg"
        const val drewn_12 = "drewn_12.jpg"
        const val drewn_13 = "drewn_13.jpg"
        const val drewn_14 = "drewn_14.jpg"
        const val drewn_15 = "drewn_15.jpg"
        const val drewn_16 = "drewn_16.jpg"
        const val drewn_17 = "drewn_17.jpg"
        const val drewn_18 = "drewn_18.jpg"
        const val drewn_19 = "drewn_19.jpg"
        const val drewn_20 = "drewn_20.jpeg"
        const val drewn_21 = "drewn_21.jpeg"
        const val drewn_22 = "drewn_22.jpeg"
        const val drewn_23 = "drewn_23.jpeg"
        const val drewn_24 = "drewn_24.jpg"
        const val drewn_25 = "drewn_25.jpg"
        const val drewn_26 = "drewn_26.jpg"
        const val drewn_27 = "drewn_27.jpg"
    }

    object Text {
        const val drewnomaniak_short_description = "Potrzebujesz wykonać altanę, taras lub jego zadaszenie, a może potrzebujesz drewaniną ławę lub leżak? A może masz pomysł na inną konstrukcję i potrzebujesz skonsultować swoją wizję i liczysz na profesjonalne jej wykonanie w konkurencyjnej cenie? Odezwij się. Myślę, że się dogadamy."
    }
}