package eu.drewnomaniak.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import eu.drewnomaniak.components.Gallery
import eu.drewnomaniak.components.SectionTitle
import eu.drewnomaniak.models.Section
import eu.drewnomaniak.models.Theme
import eu.drewnomaniak.styles.PortfolioArrowIconStyle
import eu.drewnomaniak.utils.ObserveViewportEntered
import eu.drewnomaniak.utils.Res
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val portfolioPhotos = listOf(
    Res.Image.drewn_1,
    Res.Image.drewn_2,
    Res.Image.drewn_3,
    Res.Image.drewn_4,
    Res.Image.drewn_5,
    Res.Image.drewn_6,
    Res.Image.drewn_7,
    Res.Image.drewn_8,
    Res.Image.drewn_9,
    Res.Image.drewn_10,
    Res.Image.drewn_11,
    Res.Image.drewn_12,
    Res.Image.drewn_13,
    Res.Image.drewn_14,
    Res.Image.drewn_15,
    Res.Image.drewn_16,
    Res.Image.drewn_17,
    Res.Image.drewn_18,
    Res.Image.drewn_19,
    Res.Image.drewn_20,
    Res.Image.drewn_21,
    Res.Image.drewn_22,
    Res.Image.drewn_23,
    Res.Image.drewn_24,
    Res.Image.drewn_25,
    Res.Image.drewn_26,
    Res.Image.drewn_27,
)

@Composable
fun PortfolioSection(
    onPhotoClicked: (String) -> Unit,
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(Theme.DarkBase.rgb)
            .id(Section.Portfolio.id)
            .padding(topBottom = 100.px),
//            .maxWidth(SECTION_WIDTH.px),
        contentAlignment = Alignment.Center
    ) {
        PortfolioContent(onPhotoClicked)
    }
}

@Composable
private fun PortfolioContent(
    onPhotoClicked: (String) -> Unit,
) {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    var animatedRotation by remember { mutableStateOf(0.deg) }

    ObserveViewportEntered(
        sectionId = Section.Portfolio.id,
        distanceFromTop = 500.0,
        onViewportEntered = {
            animatedRotation = 10.deg
            scope.launch {
                delay(500)
                animatedRotation = 0.deg
            }
        }
    )

    Column(
        modifier = Modifier
            .fillMaxWidth(if (breakpoint >= Breakpoint.MD) 100.percent else 90.percent),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SectionTitle(
            modifier = Modifier
                .fillMaxWidth()
                .margin(bottom = 25.px)
                .transform { rotate(animatedRotation) }
                .transition(Transition.of(property = "transform", duration = 500.ms)),
            section = Section.Portfolio,
            alignment = Alignment.CenterHorizontally
        )
        Gallery(
            portfolioPhotos,
            onPhotoClicked = onPhotoClicked,
        )
        PortfolioPhotosNavigation(breakpoint)
    }
}

@Composable
private fun PortfolioPhotosNavigation(
    breakpoint: Breakpoint,
) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.Center
    ) {
        FaArrowLeft(
            modifier = PortfolioArrowIconStyle.toModifier()
                .margin(right = 40.px)
                .cursor(Cursor.Pointer)
                .onClick {
                    document.getElementById("scrollableContainer")
                        ?.scrollBy(x = if (breakpoint >= Breakpoint.MD) -925.0 else -225.0, y = 0.0)
                },
            size = IconSize.LG
        )

        FaArrowRight(
            modifier = PortfolioArrowIconStyle.toModifier()
                .margin(right = 40.px)
                .cursor(Cursor.Pointer)
                .onClick {
                    document.getElementById("scrollableContainer")
                        ?.scrollBy(x = if (breakpoint >= Breakpoint.MD) 925.0 else 225.0, y = 0.0)
                },
            size = IconSize.LG
        )
    }
}
